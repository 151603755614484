export const environment = {
  production: true,
  API_URL: 'https://cldapi.hsiplatformval.com/api/',
  API_AICC_URL: 'https://content-aicc-api-as-val-us1.hsiplatformval.com/api/',
  API_SCORM_URL: 'https://content-scormdata-api-as-val-us1.hsiplatformval.com/api/',
  API_COURSE_INFORMATION: 'https://aiccdev2.hsicontent.com/CourseInfo.asmx/GetCourseInfo',
  minutes_of_inactivity: 10,
  WALK_ME: 'https://cdn.walkme.com/users/8a48d0b284624dd199d01c05c94fa618/test/walkme_8a48d0b284624dd199d01c05c94fa618_https.js'

};
