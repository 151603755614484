export const environment = {
  production: true,
  API_URL: 'https://cldapi.hsiplatformval.com/api/',
  API_AICC_URL: 'https://content-aicc-api-as-val-us1.hsiplatformval.com/api/',
  API_SCORM_URL: 'https://content-scormdata-api-as-val-us1.hsiplatformval.com/api/',
  API_COURSE_INFORMATION: 'https://aiccdev2.hsicontent.com/CourseInfo.asmx/GetCourseInfo',
  minutes_of_inactivity: 10,
  WALK_ME: 'https://cdn.walkme.com/users/8a48d0b284624dd199d01c05c94fa618/test/walkme_8a48d0b284624dd199d01c05c94fa618_https.js',
  SSO_Config: {
    clientId: "f72be7f3-e26f-4b8f-b6c6-c3df1b3a39d9",
    authority: "https://sts.windows.net/4b6d5d89-e8e6-4dc4-8977-22f958a63d2e",
    redirectUri: 'https://cld.hsiplatformval.com/login/sso' // change redirect url based on where u want to redirect after the authentication
  }

};
